import React from 'react';
import { Timeline, Text } from '@mantine/core';
import UpdateIcon from '@mui/icons-material/Update';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { put } from 'src/api/api';
import { useQueryClient } from 'react-query';
import { List } from '@mantine/core';

export default function ChangeLog() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const localConfig = queryClient.getQueryData(['local/config']);

  const currentVersion = localConfig?.version ?? '1.0';

  const helpParam = queryClient.getQueryData(['params/info']);
  const lastViewedVersion = helpParam?.infoViewData?.changelog ?? '1.0';

  React.useEffect(() => {
    async function updateParams() {
      put('params/changelogViewed', { currentVersion });
    }
    if (lastViewedVersion < currentVersion) {
      updateParams();
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <Box>
        <Timeline color="red" active={0} bulletSize={22}>
          <Timeline.Item bullet={<UpdateIcon size={12} />} title={t('VERSION') + ' 1.9'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_9_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_9_L2')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_8_L3')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              11.01.2025
            </Text>
          </Timeline.Item>
          <Timeline.Item bullet={<UpdateIcon size={12} />} title={t('VERSION') + ' 1.8'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_8_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_8_L2')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_8_L3')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_8_L4')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              15.06.2024
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.7'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_7_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_7_L2')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_7_L3')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_7_L4')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_7_L5')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_7_L6')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              19.04.2024
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.6'}>
            <List>
              <List.Item>
                <b>{t('CHANGE_LOG_1_6_L1')}</b>
                <List withPadding listStyleType="disc">
                  <List.Item>{t('CHANGE_LOG_1_6_L1_1')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_6_L1_2')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_6_L1_3')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_6_L1_4')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_6_L1_5')}</List.Item>
                </List>
              </List.Item>
              <List.Item>
                <b>{t('CHANGE_LOG_1_6_L2')}</b>
                <List withPadding listStyleType="disc">
                  <List.Item>{t('CHANGE_LOG_1_6_L2_1')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_6_L2_2')}</List.Item>
                </List>
              </List.Item>
            </List>
            <Text size="xs" mt={4}>
              27.11.2024
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.5'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_5_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_5_L2')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              11.11.2023
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.3'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_3_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_3_L2')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_3_L3')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_3_L4')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_3_L5')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              11.06.2023
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.2'}>
            <List>
              <List.Item>
                {t('CHANGE_LOG_1_2_L1')}
                <List withPadding listStyleType="disc">
                  <List.Item>{t('CHANGE_LOG_1_2_L1_1')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_2_L1_2')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_2_L1_3')}</List.Item>
                </List>
              </List.Item>
              <List.Item>
                {t('CHANGE_LOG_1_2_L2')}
                <List withPadding listStyleType="disc">
                  <List.Item>{t('CHANGE_LOG_1_2_L2_1')}</List.Item>
                  <List.Item>{t('CHANGE_LOG_1_2_L2_2')}</List.Item>
                </List>
              </List.Item>
            </List>
            <Text size="xs" mt={4}>
              17.10.2022
            </Text>
          </Timeline.Item>
          <Timeline.Item title={t('VERSION') + ' 1.1'}>
            <List>
              <List.Item>{t('CHANGE_LOG_1_1_L1')}</List.Item>
              <List.Item>{t('CHANGE_LOG_1_1_L6')}</List.Item>
            </List>
            <Text size="xs" mt={4}>
              30.04.2022
            </Text>
          </Timeline.Item>

          <Timeline.Item title={t('VERSION') + ' 1.0'}>
            <Text size="xs" mt={4}>
              27.03.2022
            </Text>
          </Timeline.Item>
        </Timeline>
      </Box>
    </Container>
  );
}
